<template>
    <div class="outDetail" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <div>
                <el-form
                    @submit.native.prevent
                    :inline="true"
                    style="text-align: right"
                    size="small"
                    label-width="80px"
                >
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="创建人">
                                <el-input v-model="form.creator" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="创建日期">
                                <el-input v-model="form.createTime" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="操作人">
                                <el-input v-model="form.updater" readonly />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="操作日期">
                                <el-input readonly v-model="form.updateTime" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="销售机构">
                                <el-input v-model="form.deptName" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="进货机构">
                                <el-input v-model="form.purchaseDeptName" readonly />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="状态">
                                <el-input :value="form.stockStatus | stockOutStatus" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="客户">
                                <el-input v-model="form.customer" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="客户电话">
                                <el-input v-model="form.customerMobile" readonly />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="客户地址">
                                <el-input v-model="form.customerAddress" readonly />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                border
                stripe
                style="width: 100%"
                :data="form.detailParams"
                size="mini"
                show-summary
                :highlight-current-row="true"
                max-height="500"
            >
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                <el-table-column label="商品名称" width="180" fixed="left">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).name }}
                    </template>
                </el-table-column>
                <el-table-column label="条码" width="120">
                    <template slot-scope="scope">
                        <div v-for="b in skuByGoodsCode(scope.row.goodsCode).bars" :key="b">{{ b }}</div>
                    </template>
                </el-table-column>
                <el-table-column label="规格" width="100">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).specs }}
                    </template>
                </el-table-column>
                <el-table-column label="类目" width="100">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).category }}
                    </template>
                </el-table-column>
                <el-table-column label="单位" width="80">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).unit }}
                    </template>
                </el-table-column>
                <el-table-column label="品牌" width="100">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).brand }}
                    </template>
                </el-table-column>
                <el-table-column label="批发价" width="80" prop="showPrices" />
                <template v-if="hasBigUnitPrivilege">
                    <el-table-column label="包装因子" width="100" prop="packFactors" />
                    <el-table-column label="采购箱数" min-width="100" prop="bigUnitCounts" />
                    <el-table-column label="散装数量" min-width="100" prop="bulkCounts" />
                </template>
                <el-table-column label="批发数量" width="100" prop="counts" />
                <el-table-column label="批发金额" width="120" prop="saleShowMoney" />
                <el-table-column label="赠品数量" width="100" prop="giftCount" />
            </el-table>
        </el-card>
    </div>
</template>
<script>
import Util from 'js/Util';
import MoneyUtils from 'js/MoneyUtils';
import UrlUtils from 'js/UrlUtils';
import StockBizCommon, { PropertyConverter } from 'js/StockBizCommon';
import GoodsCommon from 'js/GoodsCommon';

export default {
    name: 'OutDetail',
    props: {
        code: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            form: {
                createTime: '',
                creator: '',
                updater: '',
                updateTime: '',
                stockStatus: '',
                deptName: '',
                purchaseDeptName: '',
                deptCode: '',
                customer: '',
                customerMobile: '',
                customerAddress: '',
                detailParams: [],
            },
            //所选商品详情列表
            goodsDetailMap: new Map(),
            hasBigUnitPrivilege: false,
        };
    },
    mounted() {
        //查询数据
        UrlUtils.QueryRemote(this, `/trade/wholesale/out/info_c/${this.code}`, (data) => {
            Util.copyProperties(data, this.form);
            //是否启用大单位权限
            UrlUtils.HasBigUnitPrivilege(this, this.form.deptCode, async (rst) => {
                this.hasBigUnitPrivilege = rst;
                const arr = await StockBizCommon.makeStockBizDetailGoodsAndParams(
                    data,
                    this.detailParamPropertyArrForShow(this.hasBigUnitPrivilege)
                );
                this.goodsDetailMap = arr.goodsDetails;
                this.form.detailParams = arr.detailParams;
            });
        });
    },
    methods: {
        detailParamPropertyArrForShow(hasBigUnitPrivilege) {
            const rst = [
                'codes',
                'goodsCode',
                'counts',
                'giftCount',
                new PropertyConverter('prices', 'showPrices', (prices) => {
                    return MoneyUtils.moneyToYuan(prices);
                }),
            ];
            if (hasBigUnitPrivilege) {
                rst.push(
                    new PropertyConverter('packFactors', 'packFactors', (packFactors, detailParam) => {
                        return Util.isEmpty(packFactors) ? 1 : packFactors;
                    }),
                    new PropertyConverter('bigUnitCounts', 'bigUnitCounts', (bigUnitCounts, detailParam) => {
                        return Util.isEmpty(bigUnitCounts)
                            ? Math.floor(detailParam.counts / detailParam.packFactors)
                            : bigUnitCounts;
                    }),
                    new PropertyConverter('bulkCounts', 'bulkCounts', (bulkCounts, detailParam) => {
                        return Util.isEmpty(bulkCounts) ? detailParam.counts % detailParam.packFactors : bulkCounts;
                    })
                );
            }
            rst.push(
                new PropertyConverter(null, 'saleShowMoney', (saleShowMoney, detailParam) => {
                    return this.computeShowMoney(detailParam);
                })
            );
            return rst;
        },
        computeCount(detailParam) {
            let counts;
            if (this.hasBigUnitPrivilege) {
                //启用大单位
                counts = this.decimal(detailParam.packFactors || 1)
                    .mul(detailParam.bigUnitCounts || 0)
                    .add(detailParam.bulkCounts || 0)
                    .toNumber();
            } else {
                //未启用大单位
                counts = detailParam.counts || 0;
            }
            return counts;
        },
        computeShowMoney(detailParam) {
            return this.decimal(detailParam.counts || this.computeCount(detailParam))
                .mul(detailParam.showPrices || 0)
                .toNumber();
        },
        goodsByCode(goodsCode) {
            return this.goodsDetailMap.get(goodsCode);
        },
        skuByGoodsCode(goodsCode) {
            return this.goodsByCode(goodsCode).sku;
        },
    },
    filters: {
        skuTypeDesc(type) {
            return GoodsCommon.skuTypeDesc(type);
        },
        m(m) {
            return MoneyUtils.formatMoney(m);
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
</style>
